"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AboutUsElement = void 0;
const lit_element_1 = require("lit-element");
require("../gallery-element/gallery-element");
let AboutUsElement = class AboutUsElement extends lit_element_1.LitElement {
    constructor() {
        super();
        this.imagesToRender = [
            "assets/FP1A1755.jpg",
            "assets/Misho_new_kegs_oct2020.jpg",
            "assets/FP1A1726.jpg"
        ];
        this.name = "Ammutsøn Craft Beer Family";
    }
    render() {
        return (0, lit_element_1.html) `
            <div class="about-us-wrapper">
                <h1 class="title">${this.name}</h1>
                <p>
                    We believe that our independence and the brewer's independence are key to beer quality and diversity.
                    Thus, only supporting brewers in their independence guarantees that we can offer the best beer 
                    selection possible.
                </p>
                <p>
                    We are always on the road, trying to meet brewers – familiar and established as well as up and coming -
                    in their own breweries as often and as much as we can, to find out what they are up to and in order to 
                    come in pole position when it comes to selecting the best, the most interesting and most innovative and
                    creative beers to satisfy your – and our – craving for good beer.
                <p>
                <p>
                    We are a family who care deeply about the freshness of the product, quality and service.
                    One of our biggest missions is to support and to push local brewers in Austria.
                    Being independent allows us to visit the brewers and to build friendships, gain knowledge on their 
                    products, and to deliver the idea of the product as it was meant, not stale and flat, but fresh and 
                    lively. We don't only sell beer, we also try our best to pass this knowledge on to our customers, so 
                    they may learn to appreciate what we offer as much as we do.
                </p>
                <p>
                    We host tap take overs to give you the oportunity to meet the brewers themselves who 
                    make your favorite beers. In doing so, we strive to support the international craft
                    beer movement by providing a space for independent brewers to present their 
                    creations as well as a space for dialogue between the brewer and the consumer for 
                    better mutual understanding. We also support the idea and movement of craft beer 
                    brewing by visiting festivals where we bond with brewers, well established and 
                    familiar faces as well as new and unfamiliar ones. These relationships have 
                    provided us with the knowledge to expand our home brewing to a larger scale. The 
                    feedback that YOU provided combined with these connections helps us to come up with beer
                    creations especially for you, the customer. We are an international family of beer lovers, 
                    whose passions are authenticity, quality and, above all, flavour, and we are eager to 
                    inspire this same passion in rookies as well as living it with other beer geeks.
                </p>
                <p>
                    We extend to you our deepest gratitude for your continued support, for your openness to new things
                    and willingness to go beyound the pale of Austrian beer culture, and of course, for chosing US as 
                    the place to do so. 
                </p>
            </div>
            <gallery-element name="About Us" .images="${this.imagesToRender}"></gallery-element>
        `;
    }
    static get styles() {
        const style = (0, lit_element_1.css) `
            :host {
                display: block;
            }

            .about-us-wrapper {
                background-color: rgba(255,255,255,1);
            }
            .title {
                font-family: Gobold Bold;
                text-align: center;
                background-color: rgba(0,0,0,1);
                color: rgba(255,255,255,1);
            }
            p {
                text-align: justify;
                margin-left: 0.5em;
                margin-right: 0.5em;
            }
        `;
        return [style];
    }
};
__decorate([
    (0, lit_element_1.property)({ type: String })
], AboutUsElement.prototype, "name", void 0);
AboutUsElement = __decorate([
    (0, lit_element_1.customElement)('about-us-element')
], AboutUsElement);
exports.AboutUsElement = AboutUsElement;
