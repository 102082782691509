"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageContainerElement = void 0;
const lit_element_1 = require("lit-element");
const ajax_service_1 = require("../shared/services/ajax/ajax.service");
// This Element(component) should be in the shared folder!
let ImageContainerElement = class ImageContainerElement extends lit_element_1.LitElement {
    constructor() {
        super();
        this.name = "ImageContainerElement";
        this.ajax = new ajax_service_1.AjaxService();
    }
    render() {
        return (0, lit_element_1.html) `
            <img src="${this.src}" />
        `;
    }
    handleFallback(ev) {
        this.src = "assets/Ammutson_Stamp_black.svg";
    }
    prefetchSource() {
        this.ajax.get(this.src).catch(this.handleFallback);
    }
    static get styles() {
        const style = (0, lit_element_1.css) `
            img {
                width: 3em;
                height: 3em;
            }
            img:before {
                content: ' ';
                display: block;
                position: absolute;
                width: 3em;
                height: 3em;
                background-image: url(assets/Ammutson_Stamp_black.svg);
            }
        `;
        return [style];
    }
};
__decorate([
    (0, lit_element_1.property)()
], ImageContainerElement.prototype, "name", void 0);
__decorate([
    (0, lit_element_1.property)()
], ImageContainerElement.prototype, "src", void 0);
ImageContainerElement = __decorate([
    (0, lit_element_1.customElement)('image-container-element')
], ImageContainerElement);
exports.ImageContainerElement = ImageContainerElement;
