"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GmapsElement = void 0;
const lit_element_1 = require("lit-element");
const MeasurementUnit_enum_1 = require("../enums/shared/MeasurementUnit.enum");
const StringUtils_1 = require("../utils/StringUtils/StringUtils");
let GmapsElement = class GmapsElement extends lit_element_1.LitElement {
    get size() {
        return this._frameSize;
    }
    set size(value) {
        const oldVal = this._frameSize;
        this._frameSize = value;
        this.requestUpdate('size', oldVal);
    }
    constructor(options, apiKey) {
        super();
        this._options = options;
        this._apiKey = apiKey;
        this._frameSize = {
            width: 100,
            height: 100,
            unit: MeasurementUnit_enum_1.MeasurementUnit.RelativeUnit.PRCT
        };
    }
    render() {
        const formatedSourceLink = StringUtils_1.StringUtils.removeAllWhitespace(`
            https://www.google.com/maps/embed/v1/place?
            q=${this._options.locationSearchQuery}&
            key=${this._apiKey}&
            center=${this._options.GeoCoordinates.Latitude},${this._options.GeoCoordinates.Longitude}&
            zoom=${this._options.ZoomLevel}&
            maptype=${this._options.MapType}
        `);
        return (0, lit_element_1.html) `
            <span class="title">Google Maps©</span>
            <iframe
                width="${this._frameSize.height}${this._frameSize.unit}"
                height="${this._frameSize.width}${this._frameSize.unit}"
                frameborder="0"
                style="border:0"
                src="${formatedSourceLink}">
            </iframe>
        `;
    }
    static get styles() {
        const style = (0, lit_element_1.css) `
            :host {
                height: cover;
                width: 100%;
            }
        `;
        return [style];
    }
};
__decorate([
    (0, lit_element_1.property)()
], GmapsElement.prototype, "size", null);
GmapsElement = __decorate([
    (0, lit_element_1.customElement)('gmaps-element')
], GmapsElement);
exports.GmapsElement = GmapsElement;
