"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GalleryElement = void 0;
const lit_element_1 = require("lit-element");
require("../shared/image-collage-element/image-collage-element");
let GalleryElement = class GalleryElement extends lit_element_1.LitElement {
    constructor() {
        super();
    }
    render() {
        return (0, lit_element_1.html) `
            <h1 class="title">Gallery</h1>
            <image-collage-element .imageURIs=${this.images}></image-collage-element>
        `;
    }
    static get styles() {
        const style = (0, lit_element_1.css) `
            :host {
                display: block;
                background-color: rgba(255,255,255,1);
            }

            .title {
                font-family: Gobold Bold;
                text-align: center;
                background-color: rgba(0,0,0,1);
                color: rgba(255,255,255,1);
            }
        `;
        return [style];
    }
};
__decorate([
    (0, lit_element_1.property)({ type: String })
], GalleryElement.prototype, "name", void 0);
__decorate([
    (0, lit_element_1.property)()
], GalleryElement.prototype, "images", void 0);
GalleryElement = __decorate([
    (0, lit_element_1.customElement)('gallery-element')
], GalleryElement);
exports.GalleryElement = GalleryElement;
