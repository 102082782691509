"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactsElement = void 0;
const lit_element_1 = require("lit-element");
require("../footer-element/footer-element");
require("../gallery-element/gallery-element");
let ContactsElement = class ContactsElement extends lit_element_1.LitElement {
    constructor() {
        super();
        this.imagesToRender = [
            "assets/team_july2020.jpg",
            "assets/team_dec2019.jpg",
            "assets/team_october2020.jpg"
        ];
        this.name = "Contact Us";
    }
    render() {
        return (0, lit_element_1.html) `
            <div class="contact-us-wrapper">
                <h1>${this.name}</h1>
                <footer-element></footer-element>
            </div>
            <gallery-element name="Team" .images="${this.imagesToRender}"></gallery-element>
        `;
    }
    static get styles() {
        const style = (0, lit_element_1.css) `
            :host {
                display: block;
            }
            .contact-us-wrapper {
                background-color: rgba(255,255,255,1);
            }
            h1 {
                text-align: center;
                background-color: rgba(0,0,0,1);
                color: rgba(255,255,255,1);
            }
        `;
        return [style];
    }
};
__decorate([
    (0, lit_element_1.property)({ type: String })
], ContactsElement.prototype, "name", void 0);
ContactsElement = __decorate([
    (0, lit_element_1.customElement)('contacts-element')
], ContactsElement);
exports.ContactsElement = ContactsElement;
