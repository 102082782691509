"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FooterElement = void 0;
const lit_element_1 = require("lit-element");
const modal_dialog_service_1 = require("../shared/services/modal-dialog/modal-dialog.service");
const MeasurementUnit_enum_1 = require("../shared/enums/shared/MeasurementUnit.enum");
const gmaps_element_1 = require("../shared/gmaps-element/gmaps-element");
const Gmaps_1 = require("../shared/interfaces/Gmaps");
const API_keys_1 = require("../../environments/API_keys");
let FooterElement = class FooterElement extends lit_element_1.LitElement {
    constructor() {
        super();
        this.modalService = new modal_dialog_service_1.ModalDialogService({ width: 100, height: 100, unit: MeasurementUnit_enum_1.MeasurementUnit.RelativeUnit.PRCT });
    }
    render() {
        return (0, lit_element_1.html) `
            <footer>
                <div class="footer how-to-find-us">${this.howToFindUs()}</div>
                <div class="footer opening-hours">${this.openingHours()}</div>
                <div class="footer contact-info">${this.contactInfo()}</div>
                <div class="footer attribution-clause">${this.attributionLicenses()}</div>
            </footer>
        `;
    }
    static get styles() {
        const style = (0, lit_element_1.css) `
            footer {
                display: flex;
                width: 100%;
                flex-direction: column;
                justify-content: space-between;
            }
            .opening-hours {
                display: inline-block;
            }
            .how-to-find-us {
                display: inline-block;
            }
            .contact-info {
                display: inline-block;
            }
            span {
                display: block;
            }
            .map-directions:hover {
                cursor: pointer;
                background-color: black;
                color: white;
            }
            .title {
                font-family: Gobold Bold;
            }
            .attribution-clause {
                font-size: 10px;
                padding-top: 2%;
            }
        `;
        return [style];
    }
    openingHours() {
        return (0, lit_element_1.html) `
            <h4 class="title">Opening Hours*</h4>
            <span>Monday - Thursday</span>
            <span>1600 - 0200</span>
            <span>Friday</span>
            <span>1600 - 0400</span>
            <span>Saturday</span>
            <span>1400 - 0400</span>
            <span>Sunday</span>
            <span>1400 - 0000</span>
            <h4>*Regular Hours</h4>
        `;
    }
    howToFindUs() {
        return (0, lit_element_1.html) `
            <h4 class="title">How To Find Us</h4>
            <h5>*Click the Address to Get Directions on Map</h5>
            <address class="map-directions" @click="${this.openDirectionsMap}">Barnabitengasse 10, 1060 Vienna, Austria</address>
        `;
    }
    contactInfo() {
        return (0, lit_element_1.html) `
            <h4 class="title">Contact Information and Reservations</h4>
            <span class="contact-details owner">Misho Omar</span>
            <span class="contact-details">+43 (664) 4799130</span>
        `;
    }
    openDirectionsMap() {
        const mapOptions = {
            GeoCoordinates: {
                Latitude: 48.198535,
                Longitude: 16.35392
            },
            ZoomLevel: 14,
            MapType: Gmaps_1.MapType.ROADMAP,
            GestureType: Gmaps_1.GestureHandlingOptions.COOPERATIVE,
            locationSearchQuery: 'Ammutson Craft Beer Dive, Vienna, Austria'
        };
        const gmaps = new gmaps_element_1.GmapsElement(mapOptions, API_keys_1.API_keys.GMapsAPIKey);
        this.modalService.openModal(gmaps);
    }
    attributionLicenses() {
        return (0, lit_element_1.html) `
            <span>
                Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a>
                from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
            </span>
            <span>
                Textures by Artyom Saqib
                <a href="https://artsqb.com">https://artsqb.com</a>
            </span>
            <span>
                Page Design by <a href="https://www.instagram.com/tamtamsari/">Sarah Häckel</a> and <a href="https://twitter.com/cpa45">Cristian Pogolsha</a>
            </span>
        `;
    }
};
__decorate([
    (0, lit_element_1.property)()
], FooterElement.prototype, "name", void 0);
FooterElement = __decorate([
    (0, lit_element_1.customElement)('footer-element')
], FooterElement);
exports.FooterElement = FooterElement;
