"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SocialMediaElement = void 0;
const lit_element_1 = require("lit-element");
require("../shared/router/nav-link-element/nav-link-element");
require("../gallery-element/gallery-element");
let SocialMediaElement = class SocialMediaElement extends lit_element_1.LitElement {
    constructor() {
        super();
        this.pics = [
            "assets/patrons_inside_dec2019.jpg",
            "assets/patrons_terace_sept2020.jpg",
            "assets/patrons_inside_jan2020.jpg"
        ];
        super.connectedCallback();
        this.name = "Social Media";
    }
    render() {
        return (0, lit_element_1.html) `
            <div class="social-media-wrapper">
                <h1 class="title">${this.name} Platforms You Can Find Us On</h1>
                <div class="buttons-container">
                    <button class="button instagram" @click="${this.openURI.bind(this, "https://www.instagram.com/ammutson/", "Ammutsøn on Instagram")}">
                        <img id="instagram-logo" src="assets/instagram.svg" />Connect on Instagram
                    </button>
                    <button class="button facebook" @click="${this.openURI.bind(this, "https://www.facebook.com/Ammutson/", "Ammutsøn on Facebook")}">
                        <img id="facebook-logo" src="assets/facebook.svg" />Connect on Facebook
                    </button>
                    <button class="button untappd" @click="${this.openURI.bind(this, "https://untappd.com/v/ammutson/7481241", "Ammutsøn on Untappd")}">
                        <img id="untappd-logo" src="assets/untappd_icon.png" />Connect on Untappd
                    </button>
                </div>
            </div>
            <gallery-element .images="${this.pics}"></gallery-element>
        `;
    }
    openURI(uri, title) {
        window.open(uri, title);
    }
    static get styles() {
        const style = (0, lit_element_1.css) `
            :host {
                display: block;
            }
            .social-media-wrapper {
                background-color: rgba(255,255,255,1);
            }
            .title {
                font-family: Gobold Bold;
                text-align: center;
                background-color: rgba(0,0,0,1);
                color: rgba(255,255,255,1);
            }
            .link {
                list-style-type: none;
                display: flex;
                justify-content: space-around;
            }
            .buttons-container {
                padding-top: 10%;
                padding-bottom: 10%;
                display: flex;
                justify-content: space-evenly;
            }
            .button {
                border: 0;
                background-color: rgba(0,0,0,1);
                color: rgba(255,255,255,1);
                font-family: Gobold Bold;
                font-size: 1rem;
                cursor: pointer;
                padding: 1%;
            }
            .untappd {
                display: flex;
                flex-direction: column;
                justify-content: center;
                background-color: orange;
            }
            .facebook {
                background-color: blue;
            }
            .instagram {
                background-color: #f34c54;
            }
            #untappd-logo {
                filter: invert(86%) sepia(100%) saturate(0%) hue-rotate(237deg) brightness(108%) contrast(101%);
            }
            #facebook-logo {
                filter: invert(86%) sepia(100%) saturate(0%) hue-rotate(237deg) brightness(108%) contrast(101%);
            }
            #instagram-logo {
                filter: invert(86%) sepia(100%) saturate(0%) hue-rotate(237deg) brightness(108%) contrast(101%);
            }
        `;
        return [style];
    }
};
__decorate([
    (0, lit_element_1.property)({ type: String })
], SocialMediaElement.prototype, "name", void 0);
SocialMediaElement = __decorate([
    (0, lit_element_1.customElement)('social-media-element')
], SocialMediaElement);
exports.SocialMediaElement = SocialMediaElement;
