"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UntappdEmbedElement = void 0;
const lit_element_1 = require("lit-element");
let UntappdEmbedElement = class UntappdEmbedElement extends lit_element_1.LitElement {
    constructor() {
        super();
        this.name = "UntappdEmbedElement";
    }
    render() {
        return (0, lit_element_1.html) `
            ${this.createOldSchoolElement('untappd-container')}
        `;
    }
    static get styles() {
        const style = (0, lit_element_1.css) `
            :host {
                display: block;
            }
        `;
        return [style];
    }
    loadJSScript(url) {
        const script = document.createElement('script');
        script.onload = this.onLoad.bind(this, 'untappd-container');
        script.async = true;
        script.type = 'text/javascript';
        script.src = url;
        return script;
    }
    createOldSchoolElement(elementID) {
        const embeddedScript = this.loadUntappdEmbedScript("21792", "82993");
        document.head.appendChild(embeddedScript);
    }
    onLoad(elementID) {
        const untappdContainer = document.createElement('div');
        untappdContainer.id = elementID;
        window.document.body.appendChild(untappdContainer);
        window.EmbedMenu.call(window, 'untappd-container');
    }
    loadUntappdEmbedScript(location, theme) {
        return this.loadJSScript(`https://business.untappd.com/locations/${location}/themes/${theme}/js`);
    }
};
__decorate([
    (0, lit_element_1.property)({ type: String })
], UntappdEmbedElement.prototype, "name", void 0);
UntappdEmbedElement = __decorate([
    (0, lit_element_1.customElement)('untappd-embed-element')
], UntappdEmbedElement);
exports.UntappdEmbedElement = UntappdEmbedElement;
