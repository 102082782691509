"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotFoundElement = void 0;
const lit_element_1 = require("lit-element");
let NotFoundElement = class NotFoundElement extends lit_element_1.LitElement {
    constructor() {
        super();
        this.name = "404 Not Found";
    }
    render() {
        return (0, lit_element_1.html) `
            <h1>${this.name} &#x1F6AB;</h1>
        `;
    }
    static get styles() {
        const style = (0, lit_element_1.css) `
            :host {
                display: block;
            }
        `;
        return [style];
    }
};
__decorate([
    (0, lit_element_1.property)({ type: String })
], NotFoundElement.prototype, "name", void 0);
NotFoundElement = __decorate([
    (0, lit_element_1.customElement)('not-found-element')
], NotFoundElement);
exports.NotFoundElement = NotFoundElement;
