"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalDialogElement = void 0;
const lit_element_1 = require("lit-element");
let ModalDialogElement = class ModalDialogElement extends lit_element_1.LitElement {
    constructor(template, options) {
        super();
        this.containerTemplate = template;
        this._options = options;
    }
    get containerTemplate() {
        return this._template;
    }
    ;
    set containerTemplate(value) {
        const oldVal = this._template;
        this._template = value;
        this.requestUpdate('containerTemplate', oldVal);
    }
    render() {
        return (0, lit_element_1.html) `
            <button class="dialog-close-btn" @click="${this.removeModal}">X</button>
            <div class="template-container">
                ${this._template}
            </div>
        `;
    }
    attachModal() {
        document.body.appendChild(this);
    }
    removeModal() {
        document.body.removeChild(this);
    }
    static get styles() {
        const style = (0, lit_element_1.css) `
            :host {
                display: block;
                position: fixed;
                z-index: 100;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                overflow: auto;
                background-color: rgb(0,0,0);
                background-color: rgb(0,0,0,0.7);
            }
            .dialog-close-btn {
                position: absolute;
                top: 2%;
                left: 1%;
                width: 2rem;
                height: 2rem;
                background-color: rgba(255,255,255,0.3);
                border: 0;
                border-radius: 100%;
                cursor: pointer;
            }
            .dialog-close-btn:hover {
                background-color: rgba(255,255,255,1);
            }

            .template-container {
                top: 25%;
                position: relative;
                margin: 0 5%;
                height: 50%;
                color: rgb(255,255,255);
            }
        `;
        return [style];
    }
};
__decorate([
    (0, lit_element_1.property)()
], ModalDialogElement.prototype, "containerTemplate", null);
ModalDialogElement = __decorate([
    (0, lit_element_1.customElement)('modal-dialog-element')
], ModalDialogElement);
exports.ModalDialogElement = ModalDialogElement;
