"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeElement = void 0;
const lit_element_1 = require("lit-element");
let HomeElement = class HomeElement extends lit_element_1.LitElement {
    constructor() {
        super();
    }
    render() {
        return (0, lit_element_1.html) `
            <h1 class="title">Independent Craft Beer in Vienna's 6th District</h1>
            <div class="philosophy-pillars">
                ${this.getQualityTemplate()}
                ${this.getIndependenceTemplate()}
                ${this.getPeopleTemplate()}
            </div>
            <div class="front-image"></div>
        `;
    }
    //NB: create custom variables for white and black and replace programmatically.
    static get styles() {
        const style = (0, lit_element_1.css) `
            :host {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .title {
                text-align: center;
                color: white;
            }
            .pillar {
                width: 20%;
                height: 10%;
                text-align: center;
                flex: 0 0 auto;
            }
            .philosophy-pillars {
                padding-top: 2%;
                padding-bottom: 3%;
                display: flex;
                justify-content: space-evenly;
                align-items: baseline;
            }
            .heading {
                background-color: rgba(255,255,255,1);
                color: rgba(0,0,0,1);
                cursor: pointer;
            }
            .heading:hover {
                color: rgba(255,255,255,1);
                background-color: rgba(0,0,0,1);
            }
            .text-content {
                display: none;
                background-color: rgba(255,255,255,1);
            }
            .opened {
                color: rgba(255,255,255,1);
                background-color: rgba(0,0,0,1);
            }
            .front-image {
                background-image: url("assets/FP1A1706.jpg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                padding: 40%;
            }
        `;
        return [style];
    }
    getQualityTemplate() {
        return (0, lit_element_1.html) `
            <div id="quality" class="pillar">
                <div @click="${this.toggleHideUnhide.bind(this, 'quality')}" class="heading">Quality</div>
                <div class="text-content">
                    The passion to deliver the best and the most honest product to you while caring
                    about outstanding knowledgeable service helps us to offer you the freshest high quality beer.
                </div>
            </div>
        `;
    }
    getIndependenceTemplate() {
        return (0, lit_element_1.html) `
            <div id="independence" class="pillar">
                <div @click="${this.toggleHideUnhide.bind(this, 'independence')}" class="heading">Independence</div>
                <div class="text-content">
                    We are proud to be independent and only partner with independent breweries, to offer you the
                    best and most diverse beer selection.
                </div>
            </div>
        `;
    }
    getPeopleTemplate() {
        return (0, lit_element_1.html) `
            <div id="people" class="pillar">
                <div @click="${this.toggleHideUnhide.bind(this, 'people')}" class="heading">People</div>
                <div class="text-content">
                    Our close relationships to brewers world-wide and our strive to connect and support the local independent craft
                    beer scene in Austria makes us an international family of beer lovers.
                </div>
            </div>
        `;
    }
    toggleHideUnhide(id) {
        const textContentContainer = this.shadowRoot.querySelector(`#${id} .text-content`);
        const headingContainer = this.shadowRoot.querySelector(`#${id} .heading`);
        const style = window.getComputedStyle(textContentContainer);
        if (style.display === 'none') {
            textContentContainer.style.setProperty('display', 'block');
            headingContainer.classList.add('opened');
        }
        else {
            textContentContainer.style.setProperty('display', 'none');
            headingContainer.classList.remove('opened');
        }
    }
};
HomeElement = __decorate([
    (0, lit_element_1.customElement)('home-element')
], HomeElement);
exports.HomeElement = HomeElement;
