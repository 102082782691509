"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageCollageElement = void 0;
const lit_element_1 = require("lit-element");
let ImageCollageElement = class ImageCollageElement extends lit_element_1.LitElement {
    constructor() {
        super();
        this.imageURIs = ["assets/Ammutson_Stamp_black.svg"];
    }
    render() {
        var _a;
        return (0, lit_element_1.html) `
            ${(_a = this.imageURIs) === null || _a === void 0 ? void 0 : _a.map(uri => (0, lit_element_1.html) `<img src="${uri}" />`)}
        `;
    }
    static get styles() {
        const style = (0, lit_element_1.css) `
            :host {
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 1%;
            }

            img {
                height: 25%;
                width: 25%;
            }
        `;
        return [style];
    }
};
__decorate([
    (0, lit_element_1.property)({ type: String })
], ImageCollageElement.prototype, "name", void 0);
__decorate([
    (0, lit_element_1.property)()
], ImageCollageElement.prototype, "imageURIs", void 0);
ImageCollageElement = __decorate([
    (0, lit_element_1.customElement)('image-collage-element')
], ImageCollageElement);
exports.ImageCollageElement = ImageCollageElement;
