"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeaderElement = void 0;
const lit_element_1 = require("lit-element");
require("../shared/router/nav-link-element/nav-link-element");
let HeaderElement = class HeaderElement extends lit_element_1.LitElement {
    constructor() {
        super();
    }
    render() {
        return (0, lit_element_1.html) `
            <div class="link-list">
                <navlink-element href="/tap">
                    <div id="tap-image" class="image-container"></div>
                </navlink-element>
                <navlink-element href="/fridge">
                    <div id="fridge-image" class="image-container"></div>
                </navlink-element>
                <navlink-element href="/menu">
                    <div id="menu-image" class="image-container"></div>
                </navlink-element>
                <navlink-element href="/">
                    <div id="home-image" class="image-container"></div>
                </navlink-element>
                <navlink-element href="/social_media">
                    <div id="social-media-image" class="image-container"></div>
                </navlink-element>
                <navlink-element href="/about_us">
                    <div id="about-us-image" class="image-container"></div>
                </navlink-element>
                <navlink-element href="/contacts">
                    <div id="contact-image" class="image-container"></div>
                </navlink-element>
            </div>
        `;
    }
    static get styles() {
        const style = (0, lit_element_1.css) `
            .link-list {
                display: flex;
                justify-content: space-evenly;
                align-items: baseline;
            }

            .image-container {
                height: 10vw;
                width: 10vw;
                border-radius: 100%;
                background-color: rgba(255,255,255,1);
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }

            .image-container:hover {
                filter: invert(86%) sepia(100%) saturate(0%) hue-rotate(237deg) brightness(108%) contrast(101%);
            }

            .image-container:active {
                filter: invert(86%) sepia(100%) saturate(0%) hue-rotate(237deg) brightness(108%) contrast(101%);
            }

            #fridge-image {
                background-image: url('assets/beer_bottle_fridge.svg');
                background-size: 50%;
            }

            #tap-image {
                background-image: url('assets/beer_tap.svg');
                background-size: 80%;
            }

            #social-media-image {
                background-image: url('assets/tvset.png');
                background-size: 65%;
            }

            #about-us-image {
                background-image: url('assets/beer_glasses.svg');
                background-size: 70%;
            }

            #menu-image {
                background-image: url('assets/menu.svg');
                background-size: 70%;
            }

            #home-image {
                background-image: url('assets/ammutson_on_white.svg');
                background-size: 70% 60%;
                background-color: rgba(0,0,0,1);
                height: 18vw;
                width: 18vw;
            }

            #contact-image {
                background-image: url('assets/team.svg');
                background-size: 90%;
            }
        `;
        return [style];
    }
};
HeaderElement = __decorate([
    (0, lit_element_1.customElement)('header-element')
], HeaderElement);
exports.HeaderElement = HeaderElement;
