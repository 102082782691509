"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TapListElement = void 0;
const lit_element_1 = require("lit-element");
const untappd_service_1 = require("../shared/services/untappd/untappd.service");
const ajax_service_1 = require("../shared/services/ajax/ajax.service");
require("../list-table-element/list-table-element");
require("../untappd-embed-element/untappd-embed-element");
const API_keys_1 = require("../../environments/API_keys");
let TapListElement = class TapListElement extends lit_element_1.LitElement {
    constructor() {
        super();
        this._linkToUntappd = "https://untappd.com/v/ammutson/7481241";
        this.name = 'Tap List';
        super.connectedCallback();
    }
    connectedCallback() {
        //this.fetchMenu();
    }
    fetchMenu() {
        return __awaiter(this, void 0, void 0, function* () {
            // Implement cache mechanism to refresh every 20 min instead of everytime the component is connected.
            const untappd = new untappd_service_1.UntappdService(new ajax_service_1.AjaxService(), API_keys_1.API_keys.UntappdAPIKey);
            this._menu = yield untappd.getTapListForLocation(60600, true);
            this._tapList = this._menu.sections.filter(section => section.name === "Tap List")[0];
            this._bottleList = this._menu.sections.filter(section => section.name === "Bottle List")[0];
            this.requestUpdate();
        });
    }
    static get styles() {
        const style = (0, lit_element_1.css) `
            :host {
                background-color: rgba(0,0,0,1);
            }
            .title {
                font-family: Gobold Bold;
                text-align: center;
            }
            .link-container {
                margin-top: 2%;
                font-family: Gobold Bold;
                text-align: center;
                background-color: rgba(255,255,255,1);
            }
            .link:visited {
                color: rgba(0,0,0,1);
                text-decoration: none;
            }
            .link:hover {
                text-decoration: none;
            }
            .link:active {
                text-decoration: none;
            }
        `;
        return [style];
    }
    //<list-table-element pagination="15" .list=${tableContents}></list-table-element>
    renderTable(tableName, tableContents) {
        return (0, lit_element_1.html) `
            <div class="link-container">
                <a class="link" href="${this._linkToUntappd}">Please follow this link for up-to-date information what's on tap and in the fridge</a>
            </div>
            <slot></slot>
        `;
    }
    renderRedirectLinks(linkName, linkURL) {
        return (0, lit_element_1.html) `
            <a href="${linkURL}">${linkName}</a>
        `;
    }
    render() {
        switch (this.display) {
            case 'tap':
                return this.renderTable(this.name, this._tapList);
            case 'fridge':
                return this.renderTable(this.name, this._bottleList);
            default:
                return (0, lit_element_1.html) `<span>Nothing here yet...</span>`;
        }
    }
};
__decorate([
    (0, lit_element_1.property)()
], TapListElement.prototype, "name", void 0);
__decorate([
    (0, lit_element_1.property)({ type: String, attribute: true })
], TapListElement.prototype, "display", void 0);
TapListElement = __decorate([
    (0, lit_element_1.customElement)('tap-list-element')
], TapListElement);
exports.TapListElement = TapListElement;
