"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavLinkElement = void 0;
const lit_element_1 = require("lit-element");
const lit_element_router_1 = require("lit-element-router");
let NavLinkElement = class NavLinkElement extends (0, lit_element_router_1.navigator)(lit_element_1.LitElement) {
    constructor() {
        super();
        this.href = '';
    }
    render() {
        return (0, lit_element_1.html) `
            <a href="${this.href}" @click="${this.linkClick}">
                <slot></slot>
            </a>
        `;
    }
    static get styles() {
        const style = (0, lit_element_1.css) `
            :host {
                display: block-inline;
            }

            a {
                color: rgba(0,0,0,1);
            }

            a:link {
                text-decoration: none;
            }
        `;
        return [style];
    }
    linkClick(e) {
        e.preventDefault();
        const routerLinkClicked = new CustomEvent('router-link-clicked', {
            detail: this.href,
            composed: true,
            bubbles: true
        });
        this.dispatchEvent(routerLinkClicked);
        this.navigate(this.href);
    }
};
__decorate([
    (0, lit_element_1.property)({ type: String })
], NavLinkElement.prototype, "href", void 0);
NavLinkElement = __decorate([
    (0, lit_element_1.customElement)('navlink-element')
], NavLinkElement);
exports.NavLinkElement = NavLinkElement;
