"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppElement = void 0;
const lit_element_1 = require("lit-element");
require("../header-element/header-element");
require("../tap-list-element/tap-list-element");
require("../footer-element/footer-element");
require("../shared/router/nav-outlet-element/nav-outlet-element");
require("../social-media-element/social-media-element");
require("../menu-element/menu-element");
require("../about-us-element/about-us-element");
require("../not-found-element/not-found-element");
require("../home-element/home-element");
require("../notifier-element/notifier-element");
require("../contacts-element/contacts-element");
const lit_element_router_1 = require("lit-element-router");
const fakeNotifier_service_1 = require("../../../test/shared/fakes/fakeNotifier.service");
let AppElement = class AppElement extends (0, lit_element_router_1.router)(lit_element_1.LitElement) {
    //Abstract somehow away all of the routing so the root component is not heavily loaded.
    static get styles() {
        return (0, lit_element_1.css) `
            :host {
                display: block;
            }
        `;
    }
    static get routes() {
        return [
            {
                name: 'home',
                pattern: ''
            },
            {
                name: 'about_us',
                pattern: 'about_us'
            },
            {
                name: 'tap',
                pattern: 'tap'
            },
            {
                name: 'menu',
                pattern: 'menu'
            },
            {
                name: 'fridge',
                pattern: 'fridge'
            },
            {
                name: 'social_media',
                pattern: 'social_media'
            },
            {
                name: 'contacts',
                pattern: 'contacts'
            },
            {
                name: 'not-found',
                pattern: '*'
            }
        ];
    }
    constructor() {
        super();
        this.fridgePics = [
            "assets/cantillon_bottle.jpg",
            "assets/ammutson_fridges.jpg",
            "assets/bfm_with_glasses.jpg"
        ];
        this.tapPics = [
            "assets/taps.jpg",
            "assets/taps_with_glasses.jpg",
            "assets/bar_counter.jpg"
        ];
        this.route = '';
        this.params = {};
        this.query = {};
    }
    router(route, params, query, data) {
        this.route = route;
        this.params = params;
        this.query = query;
    }
    render() {
        return (0, lit_element_1.html) `
            <notifier-element user="ammutson" .notifierClient="${new fakeNotifier_service_1.FakeNotifier()}"></notifier-element>
            <header-element class="header"></header-element>
            <div class="body">
                <nav-outlet-element active-route="${this.route}">
                    <menu-element route="menu"></menu-element>
                    <social-media-element route="social_media"></social-media-element>
                    <contacts-element route="contacts"></contacts-element>
                    <tap-list-element route="fridge" name="Fridge List" display="fridge">
                        <gallery-element .images="${this.fridgePics}"></gallery-element>
                    </tap-list-element>
                    <tap-list-element route="tap" name="Tap List" display="tap">
                        <gallery-element .images="${this.tapPics}"></gallery-element>
                    </tap-list-element>
                    <home-element route="home"></home-element>
                    <about-us-element route="about_us"></about-us-element>
                    <not-found-element route="not-found"></not-found-element>
                </nav-outlet-element>
            </div>
        `;
    }
};
AppElement = __decorate([
    (0, lit_element_1.customElement)("app-element")
], AppElement);
exports.AppElement = AppElement;
