"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RubricElement = void 0;
const lit_element_1 = require("lit-element");
let RubricElement = class RubricElement extends lit_element_1.LitElement {
    constructor() {
        super();
    }
    render() {
        return (0, lit_element_1.html) `
            <span class="title">${this.name}</span>
            ${this.renderMenuTable()}
        `;
    }
    static get styles() {
        const style = (0, lit_element_1.css) `
            :host {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .title {
                background-color: black;
                color: white;
                font-family: "Gobold Regular";
                text-align: center;

            }
            .menu-table {
                display: none;
            }
            .subheading {
                font-size: 1.5rem;
                font-weight: bold;
            }
            .menu-item-volume {
                text-align: center;
            }
            .menu-item-price {
                text-align: center;
            }
            .menu-item-detail {
                border: 1px solid;
            }
        `;
        return [style];
    }
    renderMenuTable() {
        var _a;
        return (0, lit_element_1.html) `
            <table class="menu-table" name="${this.name}">
                <tr>
                    <th>Name</th>
                    <th>Vol</th>
                    <th>Price</th>
                </tr>
            ${(_a = this.items) === null || _a === void 0 ? void 0 : _a.map((item) => item.subtype).filter(this.distinct).map((item) => {
            var _a;
            return (0, lit_element_1.html) `
                        <tr class="subheading">${item}</tr>
                        ${(_a = this.items) === null || _a === void 0 ? void 0 : _a.filter((menuitem) => menuitem.subtype === item).map((menuitem, ix) => {
                return (0, lit_element_1.html) `
                                    <tr>
                                        <td @click=${this.toggleMenuItemVisibility.bind(this, menuitem, ix)}>${menuitem.name}</td>
                                        <td class="menu-item-volume">${menuitem.volume}</td>
                                        <td class="menu-item-price">${menuitem.price}</td>
                                    </tr>
                                    <tr style="display: none" id="menu-${this.toKebapCase(menuitem.subtype)}-detail-${ix}">
                                        <td class="menu-item-detail" >${menuitem.detail}</td>
                                    </tr>
                                `;
            })}
                    `;
        })}
            </table>
        `;
    }
    // move to separate utils class
    distinct(value, index, self) {
        return self.indexOf(value) === index;
    }
    toggleMenuItemVisibility(item, index) {
        const tableItem = this.shadowRoot.querySelector(`#menu-${this.toKebapCase(item.subtype)}-detail-${index}`);
        tableItem.style.display = tableItem.style.display === 'none' ? 'table-row' : 'none';
    }
    toKebapCase(value) {
        return value.replace(' ', '-').toLowerCase();
    }
};
__decorate([
    (0, lit_element_1.property)({ type: String })
], RubricElement.prototype, "name", void 0);
__decorate([
    (0, lit_element_1.property)()
], RubricElement.prototype, "items", void 0);
RubricElement = __decorate([
    (0, lit_element_1.customElement)('rubric-element')
], RubricElement);
exports.RubricElement = RubricElement;
