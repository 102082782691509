"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuElement = void 0;
const lit_element_1 = require("lit-element");
const ConsumableType_1 = require("../shared/enums/Ammutson/ConsumableType");
const ajax_service_1 = require("../shared/services/ajax/ajax.service");
require("../shared/rubric-element/rubric-element");
require("../gallery-element/gallery-element");
let MenuElement = class MenuElement extends lit_element_1.LitElement {
    constructor() {
        super();
        this.imagesInMenu = [
            "assets/board_games.jpg",
            "assets/cheese_platter.jpg",
            "assets/wall_art.jpg"
        ];
        this.name = "Menu";
        this.http = new ajax_service_1.AjaxService();
        super.connectedCallback();
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield (yield this.http.get("assets/menu_items.json")).json();
            this.list = response.menuItems;
        });
    }
    render() {
        return (0, lit_element_1.html) `
            <div class="menu-wrapper">
                <h1 class="title">${this.name}</h1>
                ${this.renderByType(ConsumableType_1.ConsumableType.WINE)}
                ${this.renderByType(ConsumableType_1.ConsumableType.SPIRITS)}
                ${this.renderByType(ConsumableType_1.ConsumableType.NONALCOHOLIC)}
                ${this.renderByType(ConsumableType_1.ConsumableType.COFFEEANDTEA)}
                ${this.renderByType(ConsumableType_1.ConsumableType.FOOD)}
            </div>
                ${this.renderAlergensLegend()}
                ${this.renderInfo()}
            <gallery-element class="gallery" .images=${this.imagesInMenu}></gallery-element>
        `;
    }
    static get styles() {
        const style = (0, lit_element_1.css) `
            :host {
                display: block;
            }
            .menu-wrapper {
                background-color: rgba(255,255,255,1);
            }
            .gallery {
                border-top: 5%;
            }
            .title {
                font-family: Gobold Bold;
                text-align: center;
                background-color: rgba(0,0,0,1);
                color: rgba(255,255,255,1);
            }
            .subtitle {
                background-color: rgba(0,0,0,1);
                color: rgba(255,255,255,1);
                font-size: 1.0rem;
                font-weight: normal;
                width: absolute;
            }
            rubric-element {
                cursor: pointer;
            }
            .drop-down-effect {
                animation-duration: 1s;
                animation-name: drop-down;
            }
            @keyframes drop-down {
                from {
                    margin-top: 0%;
                }
                to {
                    margin-top: 100%;
                }
            }
            .rise-up-effect {
                animation-duration: 1s;
                animation-name: rise-up;
            }
            @keyframes rise-up {
                from {
                    margin-top: 100%;
                }
                to {
                    margin-top: 0%;
                }
            }
            .section-wrapper {
                background-color: rgba(255,255,255,1);
                margin-top: 2em;
            }
        `;
        return [style];
    }
    renderInfo() {
        return (0, lit_element_1.html) `
            <div class="section-wrapper">
                <div class="subtitle">Info</div>
                Click on the menu items to see more details.
            </div>
        `;
    }
    renderAlergensLegend() {
        return (0, lit_element_1.html) `
                <div class="section-wrapper">
                    <div class="subtitle">Alergens</div>
                    <table>
                        <tr id="A">
                            <td>A</td>
                            <td>can contain gluten</td>
                        </tr>
                        <tr id="C">
                            <td>C</td>
                            <td>can contain egg</td>
                        </tr>
                        <tr id="G">
                            <td>G</td>
                            <td>can contain milk or lactose</td>
                        </tr>
                    </table>
                </div>
            `;
    }
    renderByType(drinkType) {
        var _a;
        const listByType = (_a = this.list) === null || _a === void 0 ? void 0 : _a.filter(item => item.type === drinkType);
        return (0, lit_element_1.html) `
            <rubric-element class="rubric"
                            @click="${this.hideUnhide.bind(this, drinkType.toString())}"
                            name="${drinkType.toString()}"
                            .items=${listByType}>
            </rubric-element>
        `;
    }
    hideUnhide(name) {
        // TODO: move to shared functionality.
        const shadows = [...this.shadowRoot.querySelectorAll(`rubric-element`)];
        shadows.map((element) => {
            const table = element.shadowRoot.querySelector('table');
            const tableName = table.getAttribute('name');
            if (tableName === name) {
                table.style.setProperty('display', 'table');
                table.classList.add('drop-down-effect');
            }
            else {
                table.style.setProperty('display', 'none');
                table.classList.add('rise-up-effect');
            }
        });
    }
};
__decorate([
    (0, lit_element_1.property)({ type: String })
], MenuElement.prototype, "name", void 0);
__decorate([
    (0, lit_element_1.property)()
], MenuElement.prototype, "list", void 0);
MenuElement = __decorate([
    (0, lit_element_1.customElement)('menu-element')
], MenuElement);
exports.MenuElement = MenuElement;
