"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavOutletElement = void 0;
const lit_element_1 = require("lit-element");
const lit_element_router_1 = require("lit-element-router");
let NavOutletElement = class NavOutletElement extends (0, lit_element_router_1.outlet)(lit_element_1.LitElement) {
    constructor() {
        super();
    }
    render() {
        return (0, lit_element_1.html) `
            <slot></slot>
        `;
    }
};
NavOutletElement = __decorate([
    (0, lit_element_1.customElement)('nav-outlet-element')
], NavOutletElement);
exports.NavOutletElement = NavOutletElement;
