"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListTableElement = void 0;
const lit_element_1 = require("lit-element");
require("../image-container-element/image-container-element");
let ListTableElement = class ListTableElement extends lit_element_1.LitElement {
    get list() {
        return this._list;
    }
    ;
    set list(value) {
        const oldValue = this._list;
        this._list = value;
        this.connectedCallback();
        this.requestUpdate('list', oldValue);
    }
    constructor() {
        super();
        // TODO: find a way to programmatically set-up the number of columns in the table:
        // based on the number of suplied th elements. -> The problem with this is an endless loop.
        this._columnsInTable = 7;
        this.pagination = 10;
        super.connectedCallback();
    }
    connectedCallback() {
        if (this.list !== undefined) {
            this._listName = this.list.name;
            this._beverages = this.list.items;
        }
    }
    static get styles() {
        return (0, lit_element_1.css) `
            :host {
                display: block;
                background-color: rgba(255,255,255,1);
            }
            table {
                border: black solid 2px;
                width: 100%;
            }
            .title {
                text-align: center;
                font-family: Gobold Bold;
                background-color: rgba(0,0,0,1);
                color: rgba(255,255,255,1);
            }
            .highlight-entry {
                background-color: rgba(0,0,0,1);
                color: rgba(255,255,255,1);
            }
            .data-placeholder {
                text-align: center;
                font-weight: bold;
            }
            tr:hover {
                cursor: pointer;
                background-color: rgba(0,0,0,1);
                color: rgba(255,255,255,1);
            }
            .button {
                box-shadow: none;
                display: inline-block;
                color: rgba(255,255,255,1);
                background-color: rgba(0,0,0,1);
                width: 2.2em;
                margin: 0.5em;
                border: 0em;
            }
            .pagination {
                display: block;
            }
        `;
    }
    render() {
        var _a;
        if (this.list === undefined) {
            return this.renderLoadingPlaceholder();
        }
        else if (((_a = this._beverages) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            return this.renderEmptyListPlaceholder();
        }
        else {
            return this.renderTable();
        }
    }
    paginateTable() {
        this.numberOfPages = Math.floor((this._beverages.length / this.pagination));
        const remainder = this._beverages.length % this.pagination;
        if (remainder !== 0)
            this.numberOfPages += 1;
        let pagesMarkUp = new Array(this.numberOfPages).fill('');
        return pagesMarkUp
            .map((item, ix) => (0, lit_element_1.html) `<button class="button" @click="${this.changePage.bind(this, ix + 1)}">${ix + 1}</button>`);
    }
    changePage(page) {
        const otherHtmlTableRows = [...this.shadowRoot.querySelectorAll(`tr[class^="table-page-"]`)];
        otherHtmlTableRows.map((htmltr, ix) => {
            if (htmltr.classList.contains(`table-page-${page}`)) {
                htmltr.style.setProperty('display', 'table-row');
                this.hideDetailedInfo(htmltr);
            }
            else {
                htmltr.style.setProperty('display', 'none');
            }
            return htmltr;
        });
    }
    hideDetailedInfo(tr) {
        if (tr.id.includes('info')) {
            tr.style.setProperty('display', 'none');
        }
        else {
            tr.classList.remove('highlight-entry');
        }
    }
    renderTable() {
        var _a;
        return (0, lit_element_1.html) `
            <h1 class="title">${this._listName}</h1>
            <h3 class="comment">*For additional information, click on each entry</h3>
            <h3 class="comment">
                Content Presentation is Powered by <a target="_blank" href="https://untappd.com/v/ammutson/7481241">Untappd.</a>
            </h3>
            ${this.paginateTable()}
            <table name="${this._listName}">
                <tr>
                    <th></th>
                    <th></th>
                    <th>Style</th>
                    <th>Name</th>
                    <th>Brewery</th>
                    <th>Cal</th>
                    <th>ABV</th>
                    <th>Rating</th>
                </tr>
                ${(_a = this._beverages) === null || _a === void 0 ? void 0 : _a.map((beverage, ix) => {
            var _a, _b, _c, _d;
            return (0, lit_element_1.html) `
                        <tr class="${`table-page-${(Math.floor(ix / this.pagination)) + 1}`}"
                            style="display: ${(Math.floor(ix / this.pagination)) + 1 === 1 ? "table-row" : "none"}"
                            @click="${this.toggleShow.bind(this, (_a = beverage.tapNumber) !== null && _a !== void 0 ? _a : ix + 1, null)}"
                            id="${this._listName}-entry-${(_b = beverage.tapNumber) !== null && _b !== void 0 ? _b : ix + 1}"
                        >
                            <td>${(_c = beverage.tapNumber) !== null && _c !== void 0 ? _c : ix + 1}</td>
                            <td><image-container-element src="${beverage.labelImage}"></image-container-element></td>
                            <td>${beverage.style}</td>
                            <td>${beverage.name}</td>
                            <td>${beverage.brewery}</td>
                            <td>${beverage.calories}</td>
                            <td>${beverage.abv}</td>
                            <td>${beverage.rating}</td>
                        </tr>
                        <tr 
                            class="${`table-page-${(Math.floor(ix / this.pagination)) + 1}`}"
                            style="display: none;"
                            id="${this._listName}-info-${(_d = beverage.tapNumber) !== null && _d !== void 0 ? _d : ix + 1}"
                        >
                            <td colspan="${this._columnsInTable}">
                                ${beverage.description === "" ? this.descriptionPlaceholder() : beverage.description}
                            </td>
                        </tr>
                    `;
        })}
            </table>
        `;
    }
    renderLoadingPlaceholder() {
        return (0, lit_element_1.html) `<span class="data-placeholder">Waiting for data...</span>`;
    }
    renderEmptyListPlaceholder() {
        return (0, lit_element_1.html) `<span class="data-placeholder">No Information Available. List is empty.</span>`;
    }
    toggleShow(index, visible) {
        const selectedElement = this.shadowRoot.querySelector(`[id="${this._listName}-entry-${index}"]`);
        const selectedElementInfo = this.shadowRoot.querySelector(`[id="${this._listName}-info-${index}"]`);
        const visibility = (visible !== null && visible !== void 0 ? visible : getComputedStyle(selectedElementInfo).display === "none") ? true : false;
        if (visibility === false) {
            selectedElementInfo.style.setProperty('display', 'none');
            selectedElement.classList.remove('highlight-entry');
        }
        else {
            selectedElementInfo.style.setProperty('display', 'table-row');
            selectedElement.classList.add('highlight-entry');
        }
    }
    descriptionPlaceholder() {
        return (0, lit_element_1.html) `No Description Available &#x26D4;`;
    }
};
__decorate([
    (0, lit_element_1.property)()
], ListTableElement.prototype, "pagination", void 0);
__decorate([
    (0, lit_element_1.property)({ type: Object })
], ListTableElement.prototype, "list", null);
ListTableElement = __decorate([
    (0, lit_element_1.customElement)('list-table-element')
], ListTableElement);
exports.ListTableElement = ListTableElement;
