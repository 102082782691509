"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotifierElement = void 0;
const lit_element_1 = require("lit-element");
const modal_dialog_service_1 = require("../shared/services/modal-dialog/modal-dialog.service");
let NotifierElement = class NotifierElement extends lit_element_1.LitElement {
    set notifierClient(value) {
        this._notifier = value;
        this.requestUpdate();
    }
    constructor(notifier) {
        super();
        this._message = "fetching latest announcements...";
        this._notifier = notifier;
    }
    connectedCallback() {
        const _super = Object.create(null, {
            connectedCallback: { get: () => super.connectedCallback }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.connectedCallback.call(this);
            //await this.getLatestAnnouncement(); //disable temporarily until a proper back-end implementation is in place: see SMTGS service going live
        });
    }
    getLatestAnnouncement() {
        return __awaiter(this, void 0, void 0, function* () {
            const messages = (yield this._notifier.fetchMessagesForUser(this.user)).data;
            this._message = messages.map((tweet) => (0, lit_element_1.html) `<h3 style="text-align: center">${tweet.text}</h3>`);
            const modalBox = new modal_dialog_service_1.ModalDialogService();
            modalBox.openModal((0, lit_element_1.html) `<h1>${this._message}</h1>`);
            return this._message;
        });
    }
    static get styles() {
        const style = (0, lit_element_1.css) `
            :host {
                display: block;
            }
        `;
        return [style];
    }
};
__decorate([
    (0, lit_element_1.property)()
], NotifierElement.prototype, "notifierClient", null);
__decorate([
    (0, lit_element_1.property)()
], NotifierElement.prototype, "user", void 0);
NotifierElement = __decorate([
    (0, lit_element_1.customElement)('notifier-element')
], NotifierElement);
exports.NotifierElement = NotifierElement;
